/* eslint-disable */
import React from 'react';
// import ReactDOM from "react-dom";
// import { isMobile } from 'react-device-detect';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Link } from '../util/router';
const PhotoBooth = ( props ) => {
  const { closeModal } = props;

  return (<>
        <div style={{ margin: 'auto', textAlign: 'center', width: '100%', minWidth: '300px', height: '100vh', paddingTop: '6vh', border: 'none', backgroundColor: 'white', zIndex: '1000000'}}>
      <IconButton style={{ position: 'fixed', top: '5px', right: '5px', zIndex: 1000 }} component={Link} to='/'>
    <CloseIcon />
          </IconButton>
                
      <iframe
        style={{ width: '100%', height: '100%', border: 'none' }}
        src="https://app.brandmatic.co/demo?OnDemo=1"
        allow="camera; autoplay; encrypted-media;" />
  </div></>);
};

export default PhotoBooth;
