import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
// import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import OpenGridContainer from "../components/OpenGridContainer";
import PhotoBooth from "../components/PhotoBooth";



function App(props) {




  
  return (
    // <>{
    //   window.self === window.top ? (window.location.replace('https://complexland.com'))
    //     :
        <QueryClientProvider>
          <ThemeProvider>
            <AuthProvider>
          <Router>
                <>
                  {/* <Navbar
                    color="default"
                    logo="https://uploads.divjoy.com/logo.svg"
                    logoInverted="https://uploads.divjoy.com/logo-white.svg"
                  /> */}
          <OpenGridContainer />
              

                  <Switch>
                    <Route exact path="/home" component={IndexPage} />

                    <Route exact path="/about" component={AboutPage} />

                    <Route exact path="/faq" component={FaqPage} />

                    <Route exact path="/contact" component={ContactPage} />

                    <Route exact path="/photobooth" component={PhotoBooth} />
                    <Route exact path="/auth/:type" component={AuthPage} />

                    <Route
                      exact
                      path="/settings/:section"
                      component={SettingsPage}
                    />

                    <Route exact path="/legal/:section" component={LegalPage} />

                    <Route
                      exact
                      path="/firebase-action"
                      component={FirebaseActionPage}
                    />

                    <Route component={NotFoundPage} />
                  </Switch>

                  {/* <Footer
                    bgColor="light"
                    size="normal"
                    bgImage=""
                    bgImageOpacity={1}
                    description="A short description of what you do here"
                    copyright={`© ${new Date().getFullYear()} Company`}
                    logo="https://uploads.divjoy.com/logo.svg"
                    logoInverted="https://uploads.divjoy.com/logo-white.svg"
                    sticky={true}
                  /> */}
                </>
          </Router>
          
            </AuthProvider>
          </ThemeProvider>
        </QueryClientProvider>
    // }</>
    );
}

export default App;
