import React from "react";
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useEvent } from "../hooks";
// import ProductModal from './ProductModal';
import PhotoBooth from './PhotoBooth';
import ARModal from './ARModal';
import { Link } from "./../util/router";

export default function OGModal(props) {
    const [open, setOpen] = React.useState(false);
    const [whichModal, setWhichModal] = React.useState(null);

    const handleOpen = (modal, event = "") => {
        setWhichModal(modal);
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
      useEvent('modal_photobooth', () => handleOpen('photobooth')); 
      useEvent('open_web_ar_tryon', () => handleOpen('ar')); 
    
      return (
        <React.Fragment>
          <Button component={Link} to='photobooth' >Open Photobooth Modal</Button>
          <Modal
              open={open}
                  onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                  <Box sx={{
                      position: 'absolute', width: '100vw', height: (whichModal === 'photobooth' || whichModal === 'ar') ? '100vh' : '70vh',
                      background: 'transparent', bottom: (whichModal === 'photobooth' || whichModal === 'ar') ? '-100vh' : '-70vh', left: 0,
                      transform: 'translate(0%, -100%)', transitionDuration: '2000ms'
                  }}>
                      {whichModal === 'photobooth' && <PhotoBooth closeModal={handleClose} />}
                      {whichModal === 'ar' && <ARModal closeModal={handleClose} />}
            </Box>
          </Modal>
        </React.Fragment>
      );
  }