import React from "react";
import Script from '@gumgum/react-script-tag';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useEvent } from '../hooks';
import PageLoader from './PageLoader';
import OGModal from "./OGModal";
import { useRouter } from '../util/router';
import { requireAuth } from "./../util/auth";

const opengridCanvasStyle = {
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    background: '#231F20'
}

const ogContainerStyle = {
    margin: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '100 !important',
    height: '100vh',
    width: '100vw',
}

const buildConfig = (filename) => {
    return {
        dataUrl: `./Build/Build/${filename}.data`,
        frameworkUrl: `./Build/Build/${filename}.framework.js`,
        codeUrl: `./Build/Build/${filename}.wasm`,
        loaderUrl: `./Build/Build/Build.loader.js`,
        streamingAssetsUrl: `./Build/StreamingAssets`,
        companyName: "Open Grid",
        productName: "opengrid_retail",
        productVersion: "0.2",
    }
}

const OpenGridContainer = (props) => {
    const [build, setBuild] = React.useState(null);
    const [openGridHasLoaded, setOpenGridHasLoaded] = React.useState(false);
    const router = useRouter();

    React.useEffect(() => {
        if (openGridHasLoaded) {
          build.SendMessage("GameController", "SetMobileMode", "1");
        //   if (teleportId) setTimeout(() => teleport(teleportId), 100);
        }
    }, [openGridHasLoaded]);
    
    useEvent('open_web_art_left', () => window.open('https://opensea.io/assets/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/2087', 'https://opensea.io/assets/'));
    useEvent('open_web_art_center', () => window.open('https://opensea.io/assets/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/8857', 'https://opensea.io/assets/'));
    useEvent('open_web_art_right', () => window.open('https://opensea.io/assets/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/3100', 'https://opensea.io/assets/'));
    useEvent('load_ready', () => {
        build.SendMessage("SceneIndex", "SelectScene", 'main');
    });
    useEvent('return_to_website', () => router.push('home')); 
    useEvent('loaded_ui_manager', () => {
        setOpenGridHasLoaded(true);
    }); 

    const onLoad = () => {
        const res = buildConfig('Build');
        setTimeout(() =>
            window.createUnityInstance(document.getElementById('opengrid-canvas'), res)
                .then((buildInstance) => setBuild(buildInstance))
                .catch((message) => alert(message)),
            500);   
    }  
    
    return (<>
        <Script type="text/javascript" src={'Build/Build/Build.loader.js'} onLoad={() => onLoad()} />
        {/* {!openGridHasLoaded && (<PageLoader height={'100vh'} style={{ zIndex: 100000}}/>)} */}
        <div style={ogContainerStyle}>
            <canvas id="opengrid-canvas" style={opengridCanvasStyle} />
        </div>
        <OGModal />
    </>);
}

export default requireAuth(OpenGridContainer);

       
   